
/**
 * @name: 已退款
 * @author: lili
 * @date: 2023-07-24 10:07
 * @description：已退款
 * @update: 2023-07-24 10:07
 */

import { Vue, Component } from "vue-property-decorator"
import { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import {orderRefundQueryApi, orderRefundExportApi, orderRefundAuditApi} from "@/apis/afterSales/refunded"
import {IOrderRefunded, IOrderRefundedQuery} from "@/apis/afterSales/refunded/types";
import config from "@/config";
import router from "@/router";

@Component({})
export default class RefundedPage extends Vue {
  config = config;
  refundDialog:boolean=false;
  refundForm:any={}
  rules = {
    status: [
      {required: true, message: '请选择审核状态', trigger: 'blur'},
    ],
    refuseReason: [
      {required: true, message: '请输入拒绝原因', trigger: 'blur'},
    ],
  }
  // 表格加载状态
  tableLoading: boolean = false;
  amount:string=''
  // 表格数据
  tableData: IOrderRefunded[] = []
  // 表格总数
  tableTotal: number = 0
  // 查询参数
  queryForm: IOrderRefundedQuery = {
    page: 1,
    limit: 10,
  }
  // 表单数据
  modelForm: Partial<IOrderRefunded> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    menu: true,
    column: [
      {
        label: "售后单编号",
        prop: "refundSn",
        align: "left",
        width: 180,
        search: true,
        slot: true,
        span:12,
        placeholder: "请输入售后单编号"
      },
      {
        label: "订单编号",
        prop: "saleOrderSn",
        align: "left",
        width: 180,
        search: true,
        span:12,
        placeholder: "请输入订单编号"
      },
      {
        label: "客户",
        prop: "userKeywords",
        align: "center",
        search: true,
        hide:true,
        viewHide:true,
        placeholder: "输入客户昵称/电话模糊搜索"
      },
      {
        label: "客户昵称",
        prop: "userNickName",
        align: "center",
        span:12,
      },
      {
        label: "客户电话",
        prop: "userPhone",
        width: 150,
        align: "center",
        span:12,
      },
      {
        label: "实付金额",
        prop: "payPrice",
        align: "center",
        span:12,
      },
      {
        label: "申请退款金额",
        prop: "refundPrice",
        width:100,
        align: "center",
        span:12,
      },
      {
        label: "退款原因",
        prop: "refundReason",
        overHidden:true,
        width:250,
        align: "center",
        search: true,
        span: 24,
      },
      {
        label: "凭证",
        prop: "refundPic",
        type: 'image',
        align: 'center',
        width: 150,
        imgPrefix: config.downloadUrl,
        imgSuffix:';',
        span: 24,
      },
      {
        label: "经办人",
        prop: "handledName",
        align: "center",
        search: true,
      },
      {
        label: "状态",//状态: 1=待审核 2=已通过 3=已拒绝
        prop: "status",
        align: 'center',
        search: true,
        type: 'select',
        dicData: [
          {
            label: "已拒绝",
            value: 3
          },
          {
            label: "已通过",
            value: 2
          },
          {
            label: "待审核",
            value: 1
          },
        ]
      },
      {
        label: "申请时间",
        prop: "applyTime",
        align: "center",
        width: 180,
        search: true,
        type: "daterange"
      },
      {
        label: "售后记录",
        prop: "records",
        align: "center",
        hide: true,
        viewSlot:true
      },
    ]
  }

  getList () {
    this.tableLoading = true
    const form: IOrderRefundedQuery = deepCopy(this.queryForm)
    if (form.applyTime && form.applyTime.length) {
      form.applyTimeStart = form.applyTime[0]
      form.applyTimeEnd = form.applyTime[1]
    } else {
      form.applyTimeStart = ""
      form.applyTimeEnd = ""
    }
    delete form.applyTime
    orderRefundQueryApi(form).then(e => {
      this.amount=e.amount;
      this.tableData = e.orderRefundVoPage.records;
      this.tableTotal =  e.orderRefundVoPage.total;
      this.tableLoading = false
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const form: IOrderRefundedQuery = deepCopy(this.queryForm)
    if (form.applyTime && form.applyTime.length) {
      form.applyTimeStart = form.applyTime[0]
      form.applyTimeEnd = form.applyTime[1]
    } else {
      form.applyTimeStart = ""
      form.applyTimeEnd = ""
    }
    delete form.applyTime
    orderRefundExportApi(form).then(e => {
      exportFile(e, "已退款信息.xlsx")
    })
  }


  /**
   * 查看详情
   */
  openDetail (row: IOrderRefunded, index: number) {
    let url='/afterSales/details'
    if(row)
      url=url+`?id=${row.id}`
    router.push({path:url})

  }
  /**
   * 打开 审核
   */
  handleShowRefund(row:IOrderRefunded, index: number){
    this.refundForm={}
    this.refundForm.id=row.id;
    this.refundDialog=true;
  }
  /**
   * 提交审核
   */
  handleRefundAffirm(){
    orderRefundAuditApi(this.refundForm).then(e => {
      if (e){
        this.refundDialog = false
        this.getList()
      }
    })
  }
  created () {
    this.getList()
  }
}
