import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IOrderRefundedQuery,IOrderRefunded} from "@/apis/afterSales/refunded/types";

/**
 * 分页查询
 * @param query 查询参数
 * @returns
 */
export const orderRefundQueryApi = (params:IOrderRefundedQuery) => get("/mall/orderRefund/query",params)


/**
 * 导出
 * @param id id
 * @returns
 */
export const orderRefundExportApi = (params:IOrderRefundedQuery) => get("/mall/orderRefund/export", params,'blob');

/**
 * 详情
 * @param id id
 * @returns
 */
export const orderRefundDetailApi = (id: string) => get("/mall/orderRefund/detail/" + id);
/**
 * 商品明细
 * @param params
 * @returns
 */
export const orderRefundGoodsListApi = (params: any) => get("/mall/orderRefundGoods/queryOrderRefundGoodsList",params);

/**
 * 审核
 * @param params
 * @returns
 */
export const orderRefundAuditApi = (params: IOrderRefunded) => postJ("/mall/orderRefund/audit",params);
